import { User, UserModel } from "@/types/UserClass"
import {
    MessageEventListener,
    ReadyState,
    SocketEvent,
    SocketEventListener,
    WebSocketService,
} from "@/types/WebSocket"
import LoggerService from "@/services/LoggerService"
import { BonzaMessage } from "@/types/AppMessage"
import { Device, fNOP } from "@/types/Device"
import { RemoteManager } from "@/types/RemoteManager"
import { AgentService } from "./AgentService"
import { ServiceLogHelper } from "@/services/helpers/ServiceLogHelper"
import {ConnectionResource} from "@/types/Connection";
import {putConnect} from "@/services/ConnectionService";

export interface IBonzaService {
    isOnline(user: UserModel): boolean
    onlineUserIds: Array<number>
    addUserOnlineListener(userId: number, listener: UserOnlineListener): void
    removeUserOnlineListener(
        userId: number,
        listener: UserOnlineListener
    ): null | UserOnlineListener
    join(): void
    leave(): void
    setGlobalRemoteConnectState(ready: boolean): void
    globalRemoteConnectState: boolean
    ownConnection: ConnectionResource | undefined
}

export interface Callback<T> {
    (item: T): void
}

export interface UserOnlineListener {
    handleUserOnline(userId: number, online: boolean): void
}

interface UserOnlineListeners {
    [userId: number]: Array<UserOnlineListener>
}

export interface UserIDs {
    userID: number | null
    userIDSJ: number | null
}
export const SJ_USERID_OFFSET: number = 100

export class BonzaService implements IBonzaService {
    private static _user: User | null = null
    private _ownConnection: ConnectionResource | undefined = undefined
    private _users: Array<UserModel> = []

    private _onlineUserIds: Array<number> = []
    private _onlineListeners: UserOnlineListeners = {}

    private _joined: boolean = false

    private _url: string = ""

    private _socketEventListeners: Array<SocketEventListener> = []
    private _messageEventListeners: Array<MessageEventListener> = []
    public _globalRemoteConnectState: boolean = false

    private logger: LoggerService = new LoggerService("BonzaService")
    private serviceLog: ServiceLogHelper = new ServiceLogHelper("BonzaService")

    public static get user(): User | null {
        return this._user
    }

    public static set user(user: User) {
        if (this._user) {
            console.warn(
                `*** User was already set for BonzaService (${this._user} but is being updated (${user}`
            )
        }
        this._user = user
    }

    public get ownConnection() {
        return this._ownConnection
    }

    public set ownConnection(connection: ConnectionResource | undefined) {
        this._ownConnection = connection
        if (connection) {
            putConnect({
                identifier: connection.identifier,
                engine_ip: connection.engine_ip,
                interface_ip: connection.interface_ip,
                tcp_port: connection.tcp_port,
                udp_port: connection.udp_port,
                udp_port_2: connection.udp_port_2,
                port_status: connection.port_status,
                metadata: connection.metadata,
            });
        }
    }

    public static getUserIDs(): UserIDs | null {
        // BonzaService.getUserIDs() return a UserIDs or null if no user yet.
        const curruser: User | null = BonzaService.user
        if (curruser === null) {
            return null
        }
        const uids: UserIDs = {
            userID: curruser.id,
            userIDSJ: curruser.id + SJ_USERID_OFFSET,
        }
        return uids
    }

    constructor() {
    }

    public setGlobalRemoteConnectState(ready: boolean) {
        this._globalRemoteConnectState = ready
    }

    public get globalRemoteConnectState(): boolean {
        return this._globalRemoteConnectState
    }

    /**
     * Adds a SocketMessageListener to the array of listeners.
     *
     * @param {SocketEventListener} listener - The listener to be added.
     *
     * @return {void}
     */
    public addEventListener(listener: SocketEventListener) {
        if (this._socketEventListeners.indexOf(listener) == -1) {
            this._socketEventListeners.push(listener)
        }
    }

    /**
     * Removes a listener from the socket message listeners array.
     *
     * @param {SocketEventListener} listener - The listener to be removed.
     *
     * @return {SocketEventListener | null} - The removed listener, or null if the listener was not found.
     */
    public removeEventListener(
        listener: SocketEventListener
    ): SocketEventListener | null {
        const x = this._socketEventListeners.indexOf(listener)
        if (x > -1) {
            return this._socketEventListeners.splice(x, 1)[0]
        } else {
            return null
        }
    }

    private notify(event: SocketEvent) {
        this._socketEventListeners.forEach((listener) =>
            listener.handleSocketEvent(event)
        )
        if (event.event instanceof MessageEvent) {
            const messageEvent = event.event
            const json = JSON.parse(messageEvent.data)
            this.serviceLog.received(json.type, json.action ?? undefined)
            this._messageEventListeners.forEach((listener) =>
                listener.handleMessageEvent(messageEvent, json)
            )
        }
    }

    /**
     *
     */
    public join() {
        if (!this._joined) {
            this._joined = true
            window.Echo.join("Bonza.Online")
                .here((users: Array<UserModel>) => {
                    this._onlineUserIds = []
                    users.forEach((user) => {
                        this.setOnline(user.id, true)
                    })
                })
                .joining((user: UserModel) => {
                    this.setOnline(user.id, true)
                })
                .leaving((user: UserModel) => {
                    this.setOnline(user.id, false)
                })
                .error((error: any) => {
                    this.logger.warn(`Echo error: ${JSON.stringify(error)}`)
                })
        }
    }

    public hello() {

    }

    public leave() {
        if (this._joined) {
            window.Echo.leave("Bonza.Online")
            this._joined = false
        }
    }

    public get users() {
        return this._users
    }

    public set users(users: UserModel[]) {
        this._users = users
    }

    public get onlineUserIds() {
        return this._onlineUserIds
    }

    /**
     * Adds a listener to be notified when a user comes online.
     *
     * @param {number} userId - The ID of the user to add the listener for, or zero to be notified of all users.
     * @param {UserOnlineListener} listener - The listener to be added.
     *
     * @return {void}
     */
    public addUserOnlineListener(userId: number, listener: UserOnlineListener) {
        if (!this._onlineListeners[userId]) {
            this._onlineListeners[userId] = []
        }
        if (this._onlineListeners[userId].indexOf(listener) == -1) {
            this._onlineListeners[userId].push(listener)
        }
    }

    /**
     * Removes a user online listener.
     *
     * @param {number} userId - The ID of the user, or zero for all users.
     * @param {UserOnlineListener} listener - The listener to remove.
     * @return {UserOnlineListener | null} - The removed listener, or null if listener not found.
     */
    public removeUserOnlineListener(
        userId: number,
        listener: UserOnlineListener
    ): UserOnlineListener | null {
        const x = this._onlineListeners[userId]
            ? this._onlineListeners[userId].indexOf(listener)
            : -1
        if (x > -1) {
            return this._onlineListeners[userId].splice(x, 1)[0]
        } else {
            return null
        }
    }

    /**
     * Adds a new message listener to the list of message event listeners.
     *
     * @param {MessageEventListener} listener - The message event listener to add.
     */
    public addMessageListener(listener: MessageEventListener) {
        if (this._messageEventListeners.indexOf(listener) == -1) {
            this._messageEventListeners.push(listener)
        }
    }

    /**
     * Removes a message event listener.
     *
     * @param {MessageEventListener} listener - The listener to be removed.
     * @return {MessageEventListener | null} - The removed listener, or null if the listener was not found.
     */
    public removeMessageListener(
        listener: MessageEventListener
    ): MessageEventListener | null {
        const x = this._messageEventListeners.indexOf(listener)
        if (x > -1) {
            return this._messageEventListeners.splice(x, 1)[0]
        } else {
            return null
        }
    }

    /**
     * Sets the online status of a user.
     *
     * @param {number} userId - The ID of the user.
     * @param {boolean} online - The online status to be set.
     * @private
     * @return {void}
     */
    private setOnline(userId: number, online: boolean) {
        const x = this._onlineUserIds.indexOf(userId)
        if (online && x == -1) {
            this._onlineUserIds.push(userId)
            this.notifyUserOnlineListeners(userId, true)
        } else if (!online && x > -1) {
            this._onlineUserIds.splice(x, 1)
            this.notifyUserOnlineListeners(userId, false)
        }
    }

    /**
     * Notifies all online listeners that the user has gone online.
     *
     * @private
     * @returns {void}
     */
    private notifyUserOnlineListeners(userId: number, online: boolean) {
        this._onlineListeners[userId]?.forEach((listener) => {
            listener.handleUserOnline(userId, online)
        })
        this._onlineListeners[0]?.forEach((listener) => {
            listener.handleUserOnline(userId, online)
        })
    }

    /**
     * Checks if a user is online.
     *
     * @param {UserModel} user - The user object to check online status.
     * @returns {boolean} - True if the user is online, false otherwise.
     */
    public isOnline(user: UserModel): boolean {
        return this._onlineUserIds.indexOf(user.id) > -1
    }

    /**
     * Retrieves the array of online user IDs.
     *
     * @return {Array<number>} - An array containing the IDs of the online users.
     */
    public getOnlineUserIds(): Array<number> {
        return this._onlineUserIds
    }

}
export const Bonza = new BonzaService()
export const Agent = new AgentService()
export const LocalDevice = new Device() // device ctor uses Agent
export const LocalRemoteManager = new RemoteManager() // RM ctor uses Agent and Bonza
