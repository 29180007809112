import { ConnectionRequest, ConnectionResource } from "@/types/Connection"
import {
    deleteRequest,
    postRequest,
    putRequest,
} from "@/services/helpers/AxiosHelper"

export const postConnect = async (request: ConnectionRequest) => {
    return await postRequest<ConnectionRequest, ConnectionResource>(
        route("api.connection.connect"),
        request,
        "Connection"
    )
}

export const putConnect = async (request: ConnectionRequest) => {
    return await putRequest<ConnectionRequest, ConnectionResource>(
        route("api.connection.update"),
        request,
        "Connection"
    )
}

export const deleteConnect = async (identifier: string) => {
    return await deleteRequest<ConnectionResource>(
        route("api.connection.disconnect", { identifier })
    )
}

export const postJoinBonzaSession = async (
    connection: string,
    session: string
) => {
    const debRouteName = "api.connection.join"
    const debRoute = route(debRouteName, { connection, session })

    const res = await postRequest<never, ConnectionResource>(
        debRoute,
        undefined,
        "Connection"
    )

    return res
}

export const postLeaveBonzaSession = async (connection: string) => {
    const debRouteName = "api.connection.leave"
    const debRoute = route(debRouteName, { connection })

    const res = await postRequest<never, ConnectionResource>(
        debRoute,
        undefined,
        "Connection"
    )

    return res
}
