import {isIP} from "@/types/RemoteManager";
import {BonzaService} from "@/services/BonzaService";
import {fNOP} from "@/types/Device";

export interface IRemoteManager {
    addRemoteChangeListener(listener: RemoteChangeListener): void
    removeRemoteChangeListener(
        listener: RemoteChangeListener
    ): RemoteChangeListener | null
}

export type VPSaveRemote = {
    IP: string
    port: string
    vol: number
    pan: number
}

export enum RISSFlags {
    RISS_Uninitialised = 0,
    RISS_SocketOpen = 1,
    RISS_LogInAttempted = 2,
    RISS_LoggedIn = 4, // todo !!! need to detect this by NOT getting a failed response - how? (timeout?)
    RISS_GotPortInfo = 8, // we need these params before can start streaming
    RISS_AudioRunning = 16,
    RISS_Ready = 32,
    RISS_Connected = 64, // not much use - because we can do multiple connects
    RISS_Error = 128,
    RISS_LoginFailed = 256, // maybe need to embed reason? eg FailedNoUSer FailedDupUser
    RISS_RESULT_AUDIO_READY = RISS_SocketOpen +
        RISS_GotPortInfo +
        RISS_AudioRunning,
}

export class RemoteInfo {
    public index: number = -1 // index in the array, -1 = invalid.
    public remoteName: string = ""
    public remoteConnectState: RemoteConnectState =
        RemoteConnectState.disconnected
    public connectedOnLan = false
    // for startStream - not sure where get these from ???
    public remoteUserID: string = "0"
    public systemID: string = "0"
    public interfaceIP: string = "" // internal eg 127.0.0.X
    public engineIP: string = "" // external
    public remoteIP: string = "" // added 240803 KB - in case of BonzaMirror this will differ from engineIP
    // from UDP data msg - not sure if useful???
    public ownUDPPort: string = ""
    public ownUDPPort2: string = "" // this should contain "port (NAT)"
    public OS: string = ""
    public NAT: string = "" // probably not used!
    public remoteTCPPort: string = ""
    public remoteTCPAddr: string = ""
    public zoneid: number = -1
    public panpn100s: string = ""
    public isTest: boolean = false

    public constructor(index: number) {
        this.index = index
    }

    public isValid(): boolean {
        const isvalid: boolean =
            this.remoteName.length > 0 &&
            this.interfaceIP.length > 0 &&
            isIP(this.interfaceIP) &&
            this.engineIP.length > 0 &&
            isIP(this.engineIP) &&
            this.ownUDPPort.length > 0 &&
            this.index >= 0
        // poss more todo ???
        if (isvalid) {
            fNOP()
        } else {
            fNOP()
        }
        return isvalid
    }

    public makeNatString(): string {
        const possstr1: string = this.ownUDPPort2
        // ???? or form it from other port and nat?
        const possstr2 = `${this.ownUDPPort} (${this.NAT})`

        return possstr2
    }
}



export class SelfInfo {
    public internalIP: string
    public internalPort: string
    public externalIP: string
    public userUDPPort: string
    public userUDPPort2: string
    public NAT: string
    public OS: string
    public externalPort: string
    public assignedUserIDSJ: string
    public constructor() {
        this.internalIP = ""
        this.internalPort = ""
        this.externalIP = ""
        this.userUDPPort = ""
        this.userUDPPort2 = ""
        this.NAT = ""
        this.OS = ""
        this.externalPort = ""
        this.assignedUserIDSJ = ""
    }

    isValid_ORG(): boolean {
        const includeNameAndIDInCheck = true // but if name is OK then userID and userIDSJ should also be OK
        if (includeNameAndIDInCheck) {
            if (
                !BonzaService.user ||
                !BonzaService.user.name ||
                BonzaService.user.name.length == 0
            ) {
                return false
            }
            if (!BonzaService.user.id || isNaN(BonzaService.user.id)) {
                return false
            }
        }
        const isValid: boolean =
            this.internalPort.length > 0 &&
            this.userUDPPort.length > 0 &&
            this.externalIP.length > 0 &&
            isIP(this.externalIP) &&
            this.internalIP.length > 0 &&
            isIP(this.internalIP)
        return isValid
    }

    isValid(): boolean {
        const includeNameAndIDInCheck = true // but if name is OK then userID and userIDSJ should also be OK
        if (includeNameAndIDInCheck) {
            if (
                !BonzaService.user ||
                !BonzaService.user.name ||
                BonzaService.user.name.length == 0
            ) {
                return false
            }
            if (!BonzaService.user.id || isNaN(BonzaService.user.id)) {
                return false
            }
        }
        const isValid1: boolean =
            this.internalPort.length > 0 &&
            this.userUDPPort.length > 0 &&
            this.externalIP.length > 0 &&
            isIP(this.externalIP) &&
            this.internalIP.length > 0 &&
            isIP(this.internalIP)
        if (isValid1) {
            return isValid1
        }
        // UoY special case: one of internal/ext only
        let isValid2: boolean = false
        if (
            (isIP(this.internalIP) || isIP(this.externalIP)) &&
            (this.internalPort.length || this.userUDPPort.length)
        ) {
            isValid2 = true
        }
        if (isValid2) {
            fNOP()
        } else {
            fNOP()
        }
        return isValid2
    }
}



export interface RemoteChangeListener {
    handleRemoteChange(
        manager: IRemoteManager,
        eventType: RemoteChangeEventType,
        info: RemoteInfo
    ): void
}

export enum RemoteConnectState {
    disconnected = 0,
    trying, // 1
    connected, // 2
    verified, // 3
}

export enum RemoteChangeEventType {
    Readyness,
    Add,
    Remove,
    RemoveAll,
}

export enum DisconnectResult {
    OK,
    NotConnected,
    NotPresent,
    BadParams,
}


export class workingDataStore {
    public TPInfo: any
    public selfInfo: SelfInfo = new SelfInfo()
    public remotes: Array<RemoteInfo> = []
    public currentRemoteInProgressName = ""
    constructor() {
        //this.remotes.push(new RemoteInfo(0)); // add a localhost at index 0 ...
    }
}


