import { Transition } from "@headlessui/react"
import { useMemo, useState } from "react"
import { Link } from "@inertiajs/react"
import LocalDeviceDialog from "./LocalDeviceDialog"
import Home from "./icons/home"
import Plus from "./icons/plus"
import Groups from "./icons/groups"
import Live from "./icons/live"
import Settings from "./icons/settings"

type ActiveNav = "home" | "group" | "stage"

export default function NavBar() {
    const expanded = useMemo(
        () => route().current() !== "stage",
        [route().current()]
    )

    const active = useMemo<ActiveNav | undefined>(() => {
        const current = route().current()
        if (
            current === undefined ||
            current.includes("dashboard") ||
            current.includes("session")
        )
            return "home"
        if (current.includes("group")) return "group"
        if (current.includes("stage")) return "stage"
    }, [route().current()])

    const [showSettings, setShowSettings] = useState(false)

    return (
        <nav
            className={`
                flex flex-col items-start justify-start gap-10 p-10 pt-20
            `}
        >
            <ul className="flex flex-col items-start justify-start gap-10">
                <NavBarLink
                    expanded={expanded}
                    label="Home"
                    href={route("dashboard")}
                    active={active === "home"}
                >
                    <Home />
                </NavBarLink>
                <NavBarLink
                    expanded={expanded}
                    label="Create new session"
                    href={route("session.create")}
                >
                    <Plus />
                </NavBarLink>
                <NavBarLink
                    expanded={expanded}
                    label="My groups"
                    href={route("group")}
                    active={active === "group"}
                >
                    <Groups />
                </NavBarLink>
                <NavBarLink
                    expanded={expanded}
                    label="Active live room"
                    href={route("stage")}
                    active={active === "stage"}
                >
                    <Live />
                </NavBarLink>
                <div className="w-full border-t-2 border-bonza-dark-semi" />
                <NavBarButton
                    expanded={expanded}
                    label="Settings"
                    onClick={() => setShowSettings(true)}
                    active={showSettings}
                >
                    <Settings />
                </NavBarButton>
            </ul>
            <LocalDeviceDialog
                open={showSettings}
                handleClose={() => setShowSettings(false)}
            />
        </nav>
    )
}

type NavBarButtonProps = {
    expanded: boolean
    label: string
    onClick?: () => void
    active?: boolean
    children?: JSX.Element
}

const NavBarButton = ({
    expanded,
    label,
    onClick,
    active = false,
    children,
}: NavBarButtonProps) => {
    return (
        <button
            type="button"
            onClick={onClick}
            className={`group flex h-8 flex-row items-center justify-start gap-6`}
            data-active={active}
        >
            <div
                className={`
                    group flex h-6 w-6 items-center justify-center
                    text-bonza-pale transition-all duration-75

                    group-data-[active=true]:text-bonza-primary

                    group-hover:text-bonza-primary
                `}
            >
                {children}
            </div>
            <Transition show={expanded}>
                <p
                    className={`
                        group max-w-[300px] text-nowrap text-lg
                        text-bonza-pale-t-70 transition-all duration-300

                        data-[closed]:max-w-0 data-[closed]:text-bonza-dark

                        group-hover:text-bonza-pale group-hover:duration-75
                    `}
                >
                    {label}
                </p>
            </Transition>
        </button>
    )
}

type NavBarLinkProps = NavBarButtonProps & {
    href: string
}

const NavBarLink = ({ href, ...rest }: NavBarLinkProps) => {
    return (
        <Link href={href}>
            <NavBarButton {...rest} />
        </Link>
    )
}
